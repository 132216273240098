@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', 'Open Sans', sans-serif;
  margin: 0;
  min-width: 100vw;
  overflow-x: hidden;
  padding: 0;
}

p {
  font-weight: 300;
}

input {
  font-size: 1em;
}

textarea {
  font-family: 'Sora', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

button {
  box-sizing: border-box;
  cursor: pointer;
}

button,
input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

strong {
  color: #fd6262;
  font-weight: 600;
}

.lazyload-wrapper {
  display: flex;
  justify-content: center;
  width: fit-content;
}

.lazyload-wrapper--parallax {
  width: 100%;
}

.bg-stripes {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 75%,
    transparent 75%,
    transparent
  );
  background-size: 20px 20px;
}

.markdown > * {
  all: revert;
  overflow-x: hidden;
}

.map > * {
  all: revert;
}
